<!DOCTYPE html>
<div class="gray-bg" [class.show]="isMenuOpened" (click)="onMenuClose()"></div>
<header class="header">
    <div class="p-grid">
      <div class="p-col-8 p-order-lg-1">
        <div class="header__left-btns">
          <button class="p-button menu-toggler" (click)="onMenuToggle()">
            <i-bs *ngIf="!isMenuOpened" name="list"  width="30px" height="30px"></i-bs>
            <i-bs *ngIf="isMenuOpened" name="x"  width="30px" height="30px"></i-bs>
          </button>
          <a class="logo" [routerLink]="['/']">
            <span class="logo__img">
                <img class="img-fluid" width="115.4" src="../../../../../assets/images/quilt.svg" alt="Quantum Portal"/>
            </span>
            <!-- <span class="logo__label__version">{{ environment().appVersion }}</span> -->
          </a>
          <a class="logo" [routerLink]="['/releasenotes']">
            <span class="logo__label__version">{{ environment().appVersion }}</span>
          </a>
          <div class="">
            <div class="header__menu" [class.open]="isMenuOpened">
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      
      </div>
      <div class="p-col-4 p-col-lg p-order-lg-3">
        <app-user *ifAuthorized></app-user>
      </div>
      <!-- <div class="p-col-12 p-col-lg p-order-lg-2">
        <div class="header__menu" [class.open]="isMenuOpened">
          <ng-content></ng-content>
        </div>
      </div> -->
    </div>
</header>