import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { MainLayoutModule } from './layouts/main-layout/main-layout.module';
import { AppComponent } from './app.component';
import { AppConfig } from './core/app.config';
import { SystemPropertiesService } from './core/services/system-properties.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        CoreModule,
        MainLayoutModule,
        AppRoutingModule
    ],
    providers: [ 
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: (config: AppConfig) => () => config.configureApp(),
            deps: [AppConfig],
            multi: true
        }, 
       // SystemPropertiesService
    ],
    declarations: [ AppComponent ],
    bootstrap: [AppComponent]
})
export class AppModule { }
 