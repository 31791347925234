import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { IToasterData } from '@common/models/IToasterData'


@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService]

})
export class ToasterComponent implements OnChanges {

  @Input() toasterData: IToasterData;


  constructor(private primengConfig: PrimeNGConfig, private messageService: MessageService) { }

  ngOnChanges(): void {
    this.primengConfig.ripple = true;
    this.messageService.add({
      severity: this.toasterData?.status,
      sticky: true,
      summary: this.toasterData?.summary,
      detail: this.toasterData?.message,
      
    });

    setTimeout(()=>{                           // <<<---using ()=> syntax
      this.messageService.clear();
  }, 3000);

  }

}
